<template>
<div class="liff-rollcall-page">
  <div class="page__toolbar">
    <div class="toolbar-back-action" @click="backToCoursePage">
      <a-button type="link">
        <template #icon>
          <LeftOutlined :style="{ color: 'white', fontWeight: 'bold' }" />
        </template>
      </a-button>
    </div>
    <div class="toolbar-title">{{ courseData.name }}</div>
  </div>

  <div class="page__body">
    <div class="body__wrapper">
      <!-- 選擇簽到的課堂 -->
      <div class="lession-selector" align="center">
        <div class="selector-label">選擇簽到課堂</div>
        <a-select
          v-model:value="lessionSelectedValue"
          :options="lessionOptions"
          @change="onChangeLessionSelectedValue"
          size="large"
        />
      </div>
      <!-- 選擇簽到的課堂 -->

      <!-- 簽到內容 -->
      <div class="rollcall-content">
        <div
          class="letwecare-survey-card"
          v-for="survey in surveyData.contents"
        >
          <div class="card-title">{{ survey.name }}</div>
          <div class="card-body" align="center">
            <template v-if="actionState == 'edit'">
              <template v-if="survey.type == 'number'">
                <a-input-number
                  v-model:value="survey.value"
                  class="body-number-input"
                  size="large"
                  :min="1"
                />
              </template>

              <template v-if="survey.type == 'text-field'">
                <a-input
                  v-model:value="survey.value"
                  class="body-input"
                  size="large"
                />
              </template>

              <template v-if="survey.type == 'radio'">
                <a-radio-group v-model:value="survey.value">
                  <a-radio  
                    v-for="opt in survey.selection_items"
                    :value="opt.label"
                    :style="{ display: 'flex', height: '40px', lineHeight: '40px', fontSize: '1.3rem' }"
                  >{{ opt.label }}</a-radio>
                </a-radio-group>
              </template>

              <template v-if="survey.type == 'checkbox'">
                <a-checkbox-group v-model:value="survey.value">
                  <a-checkbox  
                    v-for="opt in survey.selection_items"
                    :value="opt.label"
                    :style="{ display: 'flex', height: '40px', lineHeight: '40px', fontSize: '1.3rem', marginLeft: 0 }"
                  >{{ opt.label }}</a-checkbox>
                </a-checkbox-group>
              </template>

              <template v-if="survey.type == 'slider'">
                <span :style="{ fontSize: '1.8rem', fontWeight: '500', marginBottom: '8px' }">{{ survey.value }}</span>
                <a-row>
                  <a-col :span="12" align="left">
                    {{ survey.rule.min_label }}
                  </a-col>
                  <a-col :span="12" align="right">
                    {{ survey.rule.max_label }}
                  </a-col>
                  <a-col :span="20" :offset="2">
                    <a-slider
                      v-model:value="survey.value"
                      :min="survey.rule.min"
                      :max="survey.rule.max"
                    />
                  </a-col>
                </a-row>
              </template>
            </template>

            <template v-if="actionState == 'lock'">
              <template v-if="Array.isArray(survey.value)">
                <div class="body-value-text text--small" v-for="text in survey.value">{{ text }}</div>
              </template>

              <template v-else>
                <span class="body-value-text" :class="{ 'text--small': survey.value.length>6 }">{{ survey.value }}</span>
              </template>
            </template>
            
            <span class="body-unit" v-if="survey.unit">{{ survey.unit }}</span>
          </div>
        </div>
      </div>
      <!-- 簽到內容 -->
    </div>
  </div>

  <div class="page__actions">
    <template v-if="actionState=='edit' && !Object.keys(rollcallData).length">
      <div class="action-btn" @click="submit">完成簽到</div>
    </template>
    
    <template v-if="actionState=='lock' && Object.keys(rollcallData).length">
      <div class="action-btn btn--white" @click="actionState='edit'">修改紀錄</div>
    </template>

    <template v-if="actionState=='edit' && Object.keys(rollcallData).length">
      <div class="action-btn btn--white" :style="{ width: '50%' }" @click="actionState='lock'">取消</div>
      <div class="action-btn" :style="{ width: '50%' }" @click="updateRollcallContents">保存紀錄</div>
    </template>
  </div>
</div>
</template>

<script>
import { defineComponent, ref, onMounted } from 'vue'
import { LeftOutlined } from '@ant-design/icons-vue'
import { message } from 'ant-design-vue'
import { useRoute } from 'vue-router'
import liff from '@line/liff/dist/lib'
import moment from 'moment'
import api from '@/apis'

export default defineComponent({
  components: {
    LeftOutlined
  },
  setup() {
    const route = useRoute();

    /* Liff */
    const context = ref({});
    const initLiff = (async () => {
      try {
        await liff.init({ liffId: process.env.VUE_APP_LIFF_MY_COURSES });
        context.value = liff.getContext();
        console.log(context.value);
      } catch (err) {
        console.log(err.code, err.message);
      }
    });

    /* Data */
    const userId = ref('');
    const courseData = ref({});
    const lessionsData = ref([]);
    const surveyData = ref({});
    const rollcallData = ref({});

    /* Action State */
    const actionState = ref('edit'); // 編輯狀態 (edit|lock)

    /* Lession Options */
    const lessionSelectedValue = ref('');
    const lessionOptions = ref([]);

    const onChangeLessionSelectedValue = (async () => {
      await getCourseSurveyData();
      await getRollcallData();
    });

    /* Api */
    const getUserId = (async () => {
      let line_id;
      if (process.env.VUE_APP_ENV == 'production') {
        line_id = context.value.userId;
      } else if (process.env.VUE_APP_ENV == 'development') {
        line_id = process.env.VUE_APP_DEV_LINE_ID;
      }

      const response = await api.v1.line.parserLineId({
        line_id: line_id
      });
      userId.value = response.data.result.uid;
    });

    const getCourseData = (async () => {
      courseData.value = {};
      
      const response = await api.v1.course.findOne(route.params.course_id);
      courseData.value = response.data.result;
    });

    const getLessionsData = (async () => {
      lessionsData.value = [];
      
      const response = await api.v1.lession.list({
        course_id: route.params.course_id
      });

      response.data.result.forEach(item => {
        // item.name = `課堂${idx+1}：${item.name}`
        lessionsData.value.push(item);

        lessionOptions.value.push({
          value: item.id,
          label: `${item.name} (${item.start_date})`
        });

        // 預設選擇當天或下一堂課的簽到
        if (moment().isBefore(`${item.start_date} 23:59:59`)) {
          if (!lessionSelectedValue.value) lessionSelectedValue.value = item.id;
        }

        // console.log(DateUtil.diff(item.start_date))
        // if (DateUtil.diff(item.start_date) > 0) {
        //   if (!lessionSelectedValue.value) lessionSelectedValue.value = item.id;
        // }
      });
    });

    const getCourseSurveyData = (async () => {
      surveyData.value = {};

      const response = await api.v1.course.surveyList(route.params.course_id, {
        type: route.query.mode
      });
      const result = response.data.result;

      if (result.length) {
        surveyData.value = result[result.length-1];

        // 加入 value 欄位
        surveyData.value.contents.forEach(item => {
          if (item.type == 'slider') {
            item.value = item.rule.min;
          }
          else {
            item.value = '';
          }
        });
      }

      console.log(surveyData.value)
    });

    const getRollcallData = (async () => {
      rollcallData.value = {};

      try {
        const response = await api.v1.lession.findOneRollcall(
          route.params.course_id,
          lessionSelectedValue.value,
          {
            user_id: userId.value,
            type: route.query.mode
          }
        );
        rollcallData.value = response.data.result;

        // 放入已簽到的資料
        surveyData.value.contents.forEach(surveyContentItem => {
          rollcallData.value.contents.forEach(rollcallContentItem => {
            if (surveyContentItem.name == rollcallContentItem.name) {
              surveyContentItem.value = rollcallContentItem.value;
            }
          });
        });

        console.log(response.data.result);
      } catch (error) {
        console.log(error)
      }

      if (Object.keys(rollcallData.value).length > 0) {
        actionState.value = 'lock';
      } else {
        actionState.value = 'edit';
      }
      console.log(actionState.value)
    });

    /* Mounted */
    onMounted(async () => {
      await initLiff();
      await getUserId();
      await getCourseData();
      await getLessionsData();
      await getCourseSurveyData();
      await getRollcallData();
    });

    return {
      /* Data */
      userId,
      courseData,
      lessionsData,
      surveyData,

      /* Action State */
      actionState,

      /* Rollcall Params */
      rollcallData,
      onChangeLessionSelectedValue,

      /* Options */
      lessionSelectedValue,
      lessionOptions,

      /* Api */
      getRollcallData
    }
  },
  methods: {
    /* Router */
    backToCoursePage() {
      this.$router.push({
        name: 'LiffCourseList',
        params: {
          course_id: this.$route.params.course_id
        }
      });
    },

    /* Function */
    async submit() {
      const paramsData = {
        type: this.$route.query.mode,
        user_id: this.userId,
        contents: this.surveyData.contents
      }

      try {
        await api.v1.lession.insertOneRollcall(
          this.$route.params.course_id,
          this.lessionSelectedValue,
          paramsData
        );
        
        this.getRollcallData();
        this.actionState = 'lock';
        message.success('完成簽到');
      } catch (error) {
        console.log(error);
        message.error('簽到發生錯誤，請稍後再試');
      }
    },
    async updateRollcallContents() {
      const paramsData = {
        contents: this.surveyData.contents
      }

      try {
        await api.v1.lession.updateOneRollcall(
          this.rollcallData.id,
          paramsData
        );
        this.actionState = 'lock';
        message.success('保存成功');
      } catch (error) {
        console.log(error);
        message.error('保存紀錄發生錯誤，請稍後再試');
      }
    }
  }
})
</script>

<style lang="scss">
.liff-rollcall-page {
  min-height: 100vh;
  background: #EAEAEA;

  .page__toolbar {
    height: 57px;
    background: #3D3D3D;
    position: relative;

    .toolbar-back-action {
      position: absolute;
      left: 12px;
      top: 12px;
    }

    .toolbar-title {
      color: white;
      text-align: center;
      font-weight: 500;
      font-size: 1.5rem;
      letter-spacing: 0.0425rem;
      line-height: 57px;
    }
  }

  .page__body {
    .body__wrapper {
      padding: 24px 20px 56px 20px;

      // .rollcall-content {
      //   padding: 0
      // }
    }
  }

  .page__actions {
    width: 100%;
    height: 52px;
    position: fixed;
    left: 0;
    bottom: 0;

    .action-btn {
      display: inline-block;
      width: 100%;
      height: 52px;
      color: white;
      background: #FF8A00;
      box-shadow: inset 0px 2px 0px rgba(0, 0, 0, 0.05);
      font-size: 1.3rem;
      font-weight: 500;
      line-height: 52px;
      text-align: center;

      &.btn--white {
        color: #212121;
        background: white !important;
      }
    }
  }

  // body
  .lession-selector {
    margin-bottom: 20px;

    .selector-label {
      font-weight: 500;
      font-size: 1.3rem;
      letter-spacing: 0.03rem;
      margin-bottom: 4px;
    }

    .ant-select {
      width: 80%;
      background: white;
      border-radius: 0;
      font-size: 1.2rem;
      margin-bottom: 16px;

      .ant-select-selection-item {
        text-align: center;
      }
    }
  }
}
</style>